<template>
    <div>Redirect TO Library</div>
</template>
<script>
import axios from "axios";
import crypto from "@/service/crypto.js";
export default {
    data() {
        return {};
    },
    mounted() {
        const data = this.$route.query.ehm.replaceAll("yashalekar@123", '+');
        let enc = crypto.decrypt(data);
        if (enc) {
            var data1 = JSON.parse(enc);
            if (data1.msg == "200") {
                localStorage.setItem("awspath", data1.awspath)
                localStorage.setItem("EPS-token", data1.token);
                localStorage.setItem("EPS-uid", data1.uid);
                localStorage.setItem("logo_url", data1.logo_url);
                localStorage.setItem("org_name", data1.org_name);
                localStorage.setItem("org_trust_name", data1.org_trust_name);
                localStorage.setItem("org_address", data1.org_address);
                if (data1.policy_read == true) {
                    localStorage.setItem("first-login", "0");
                    this.$router.push("/home");
                }
            }
        }
    },
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    },
};
</script>
<style scoped></style>